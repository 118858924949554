import { Activity } from './activity';
import { Model, ModelDataWithoutFunctions, ModelParameter } from './model';
import { User } from './user';

export class Comment extends Model {
    content: ModelParameter<string>;
    user: ModelParameter<User>;
    activity: ModelParameter<Activity>;

    constructor(data?: ModelDataWithoutFunctions<Comment>) {
        super(data);

        if (data) {
            this.content = data.content;

            if (data.user) {
                this.user = new User(data.user);
            }

            if (data.activity) {
                this.activity = new Activity(data.activity);
            }
        }
    }
}

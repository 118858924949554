import { Model, ModelDataWithoutFunctions, ModelParameter } from './model';
import { Role } from './role';

export class User extends Model {
    email: ModelParameter<string>;
    verified: ModelParameter<boolean>;
    password: ModelParameter<string>;
    firstName: ModelParameter<string>;
    lastName: ModelParameter<string>;
    fullName: ModelParameter<string>;

    roles: ModelParameter<Role[], []> = [];

    constructor(data?: ModelDataWithoutFunctions<User>) {
        super(data);

        if (data) {
            this.email = data.email;
            this.verified = data.verified;
            this.password = data.password;
            this.firstName = data.firstName;
            this.lastName = data.lastName;

            if (data.roles) {
                this.roles = data.roles.map((role) => new Role(role));
            }

            this.fullName = `${this.firstName} ${this.lastName}`;
        }
    }
}

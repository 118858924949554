import { Model, ModelParameter, ModelDataWithoutFunctions } from './model';

export class Reservation extends Model {
    address: ModelParameter<string>;
    adminComment: ModelParameter<string>;
    city: ModelParameter<string>;
    country: ModelParameter<string>;
    email: ModelParameter<string>;
    finalPaymentAmount: ModelParameter<number>;
    finalPaymentDate: ModelParameter<string>;
    firstName: ModelParameter<string>;
    handBinoculars: ModelParameter<boolean>;
    lastName: ModelParameter<string>;
    miscInfo: ModelParameter<string>;
    paymentStatus: ModelParameter<any>;
    phoneNumber: ModelParameter<string>;
    postalCode: ModelParameter<string>;
    registrationFeePaymentAmount: ModelParameter<number>;
    registrationFeePaymentDate: ModelParameter<string>;
    scopeBinoculars: ModelParameter<boolean>;
    singleBedroom: ModelParameter<boolean>;
    socialSecurityNumber: ModelParameter<string>;
    suggestDoubleRoomPartner: ModelParameter<boolean>;
    suggestedDoubleRoomPartner: ModelParameter<string>;
    transactionUuid: ModelParameter<string>;
    type: ModelParameter<string>;
    finalInvoiceDate: ModelParameter<string>;
    personalInformationForm: ModelParameter<any>;

    constructor(data?: ModelDataWithoutFunctions<Reservation>) {
        super(data);

        if (data) {
            this.address = data.address;
            this.adminComment = data.adminComment;
            this.city = data.city;
            this.country = data.country;
            this.email = data.email;
            this.finalPaymentAmount = data.finalPaymentAmount;
            this.finalPaymentDate = data.finalPaymentDate;
            this.firstName = data.firstName;
            this.handBinoculars = data.handBinoculars;
            this.lastName = data.lastName;
            this.miscInfo = data.miscInfo;
            this.paymentStatus = data.paymentStatus;
            this.phoneNumber = data.phoneNumber;
            this.postalCode = data.postalCode;
            this.registrationFeePaymentAmount = data.registrationFeePaymentAmount;
            this.registrationFeePaymentDate = data.registrationFeePaymentDate;
            this.scopeBinoculars = data.scopeBinoculars;
            this.singleBedroom = data.singleBedroom;
            this.socialSecurityNumber = data.socialSecurityNumber;
            this.suggestDoubleRoomPartner = data.suggestDoubleRoomPartner;
            this.suggestedDoubleRoomPartner = data.suggestedDoubleRoomPartner;
            this.transactionUuid = data.transactionUuid;
            this.type = data.type;
            this.finalInvoiceDate = data.finalInvoiceDate;
            this.personalInformationForm = data.personalInformationForm;
        }
    }
}

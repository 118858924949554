import { Model, ModelDataWithoutFunctions, ModelParameter } from './model';

export class Folder extends Model {
    title: ModelParameter<string>;
    children: ModelParameter<Folder[], []> = [];
    parent: ModelParameter<Folder>;
    parentUuid: ModelParameter<string>;

    constructor(data?: ModelDataWithoutFunctions<Folder>) {
        super(data);

        if (data) {
            this.title = data.title;
            this.parentUuid = data.parentUuid;

            if (data.children) {
                this.children = data.children.map((child) => new Folder(child));
            }

            if (data.parent) {
                this.parent = new Folder(data.parent);
            }
        }
    }
}

export type ModelDataWithoutFunctions<T> = Partial<{ [Key in keyof T]: T[Key] extends Function ? never : T[Key] }> | T;
export type ModelClass<T> = new (...args: any) => T;
export type ModelParameter<T, E = undefined> = T | E;

export class Model {
    uuid: ModelParameter<string>;
    updatedAt: ModelParameter<string>;
    createdAt: ModelParameter<string>;

    constructor(data?: ModelDataWithoutFunctions<Model>) {
        if (data) {
            this.uuid = data.uuid;
            this.updatedAt = data.updatedAt;
            this.createdAt = data.createdAt;
        }
    }
}

import { Model, ModelDataWithoutFunctions, ModelParameter } from './model';

export class Role extends Model {
    title: ModelParameter<string>;
    permissions: ModelParameter<string[], []> = [];

    constructor(data?: ModelDataWithoutFunctions<Role>) {
        super(data);

        if (data) {
            this.title = data.title;

            if (data.permissions) {
                this.permissions = data.permissions;
            }
        }
    }
}

import { State, StateContext, Action, Selector, Store, NgxsOnInit } from '@ngxs/store';
import { inject, Injectable } from '@angular/core';

import { catchError, tap } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { JwtHelperService } from '@auth0/angular-jwt';

import { User } from '@shared/models';
import { AuthenticationSuccessful, AuthencticationUnsuccessful, Authenticate, SignOut, SetToken, RefreshToken } from './auth.actions';
import { isAfter, sub } from 'date-fns';
import { HttpService } from '@shared/services/http';

const jwtHelper = new JwtHelperService();

export interface AuthStateModel {
    user: User | null;
    token: string | null;
    refreshToken: string | null;
    isCheckingToken: boolean | null;
}

const defaults: AuthStateModel = {
    user: null,
    token: null,
    refreshToken: null,
    isCheckingToken: false,
};

@State({
    name: 'auth',
    defaults,
})
@Injectable()
export class AuthState implements NgxsOnInit {
    private readonly api = inject(HttpService);
    private readonly store = inject(Store);

    @Selector()
    static user({ user }: AuthStateModel) {
        return user;
    }

    @Selector()
    static token({ token }: AuthStateModel) {
        return token;
    }

    @Selector()
    static refreshToken({ refreshToken }: AuthStateModel) {
        return refreshToken;
    }

    @Selector()
    static everything(state: AuthStateModel) {
        return state;
    }

    @Selector()
    static isCheckingToken({ isCheckingToken }: AuthStateModel) {
        return isCheckingToken;
    }

    ngxsOnInit(ctx: StateContext<AuthStateModel>) {
        ctx.patchState({ isCheckingToken: false });
    }

    @Action(Authenticate)
    authenticate({ patchState, dispatch }: StateContext<AuthStateModel>, { credentials }: Authenticate): Observable<any> {
        return this.api
            .post('auth/login', credentials)
            .expand('roles')
            .execute()
            .pipe(
                catchError((err) => {
                    dispatch(new AuthencticationUnsuccessful());

                    return throwError(() => err);
                }),
                tap((result: any) => {
                    patchState({
                        user: new User(result.data.user),
                        refreshToken: result.data.refreshToken,
                    });

                    dispatch([new SetToken(result.data.token), new AuthenticationSuccessful()]);
                }),
            );
    }

    @Action(SignOut)
    signOut({ patchState }: StateContext<AuthStateModel>): void {
        patchState(defaults);
    }

    @Action(SetToken)
    setToken({ patchState }: StateContext<AuthStateModel>, { token }: SetToken): void {
        patchState({ token });

        this.api.setToken(token);
    }

    @Action(AuthenticationSuccessful)
    authenticationSuccessful({ patchState, getState }: StateContext<AuthStateModel>): void {
        const { isCheckingToken } = getState();

        if (!isCheckingToken) {
            this.checkToken();

            patchState({ isCheckingToken: true });
        }
    }

    @Action(RefreshToken)
    refreshToken({ patchState, getState, dispatch }: StateContext<AuthStateModel>): Observable<any> {
        const { refreshToken, isCheckingToken } = getState();

        return this.api
            .post('auth/refresh', { refreshToken })
            .expand('roles')
            .execute()
            .pipe(
                tap((result: any) => {
                    patchState({ user: new User(result.data.user) });

                    dispatch(new SetToken(result.data.token));

                    if (!isCheckingToken) {
                        this.checkToken();

                        patchState({ isCheckingToken: true });
                    }
                }),
            );
    }

    private checkToken() {
        const token = this.store.selectSnapshot(AuthState.token);

        if (token) {
            const expDate = jwtHelper.getTokenExpirationDate(token);

            if (expDate) {
                const expDateMinusThreeMinutes = sub(expDate, { minutes: 3 });

                if (isAfter(new Date(), expDateMinusThreeMinutes)) {
                    this.store.dispatch(new RefreshToken());
                }
            }
        }

        setTimeout(() => this.checkToken(), 15000);
    }
}

import { HttpRequest, HttpHeaders, HttpEventType, HttpResponse, HttpParams, HttpErrorResponse } from '@angular/common/http';
import { filter, map, catchError, timeout } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { orderBy } from 'natural-orderby';
import { HttpRequestMethod } from './symbols';
import { HttpService } from './http.service';
import { environment } from '@env';

export class CustomHttpRequest {
    private _method: HttpRequestMethod;
    private _url: string;
    private _body: any;
    private _headers: HttpHeaders;
    private _params: HttpParams = new HttpParams();
    private _model: any;
    private _sort: HttpLocalSortInterface;

    constructor(protected httpService: HttpService) {
        this._headers = httpService.headers;
    }

    body(body: any): this {
        this._body = body;

        return this;
    }

    execute<T = any>(): Observable<T> {
        if (this._body instanceof FormData) {
            this._headers = this._headers.delete('Content-Type');
        }

        const request = new HttpRequest(this._method as string, this._url, this._body ? this._body : null, {
            headers: this._headers,
            params: this._params,
            reportProgress: false,
        });

        return this.httpService.http.request<any>(request).pipe(
            timeout(30000),
            filter((res: any) => res.type === HttpEventType.Response),
            catchError((err: HttpErrorResponse) => {
                return throwError(() => err.error);
            }),
            map((res: any) => {
                const { body } = res;

                if (this._model && body.data) {
                    if (Array.isArray(body.data)) {
                        body.data = body.data.map((i: any) => new this._model(i));

                        if (this._sort) {
                            body.data = orderBy(body.data, this._sort.key, this._sort.direction);
                        }
                    } else {
                        body.data = new this._model(body.data);
                    }

                    return body.data;
                }

                return body;
            }),
        );
    }

    executeToModel<T>(model: new () => T): Observable<T> {
        return this.transform(model).execute();
    }

    executeToCollection<T>(model: new () => T): Observable<T[]> {
        return this.transform(model).execute();
    }

    transform(model: any): this {
        this._model = model;

        return this;
    }

    expand(...expand: string[]): this {
        this._params = this._params.set('expand', JSON.stringify(expand));

        return this;
    }

    localSort(sortOptions: HttpLocalSortInterface): this {
        this._sort = sortOptions;

        return this;
    }

    header(key: string, value: string): this {
        this._headers = this._headers.set(key, value);

        return this;
    }

    method(method: HttpRequestMethod): this {
        this._method = method;

        return this;
    }

    parameters(params: { [key: string]: string }): this {
        for (const param in params) {
            if (params[param]) {
                this._params = this._params.set(param, params[param]);
            }
        }

        return this;
    }

    token(token: string): this {
        if (token) {
            this._headers = this._headers.set('Token', token);
        }

        return this;
    }

    excludeToken(): this {
        this._headers = this._headers.delete('Token');

        return this;
    }

    unit(uuid: string): this {
        if (uuid) {
            this._headers = this._headers.set('Unit', uuid);
        }

        return this;
    }

    url(url: string): this {
        this._url = [environment.api.url, url].join('/');

        return this;
    }
}

export interface HttpLocalSortInterface {
    key: string;
    direction: 'asc' | 'desc';
}

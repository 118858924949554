import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { CustomHttpRequest } from './http-request';
import { HttpRequestMethod } from './symbols';

@Injectable({ providedIn: 'root' })
export class HttpService {
    public headers: HttpHeaders;

    constructor(@Inject(HttpClient) public http: HttpClient) {
        this.headers = new HttpHeaders({ 'content-type': 'application/json' });
    }

    delete(endpoint: string, id?: string | number): CustomHttpRequest {
        endpoint = id ? [endpoint, id].join('/') : endpoint;

        return new CustomHttpRequest(this).url(endpoint).method(HttpRequestMethod.Delete);
    }

    get(endpoint: string): CustomHttpRequest {
        return new CustomHttpRequest(this).url(endpoint).method(HttpRequestMethod.Get);
    }

    post(endpoint: string, body: any): CustomHttpRequest {
        return new CustomHttpRequest(this).url(endpoint).body(body).method(HttpRequestMethod.Post);
    }

    put(endpoint: string, body: any, id?: string | number): CustomHttpRequest {
        endpoint = id ? [endpoint, id].join('/') : endpoint;

        return new CustomHttpRequest(this).url(endpoint).body(body).method(HttpRequestMethod.Put);
    }

    show(endpoint: string, id: string | number): CustomHttpRequest {
        return this.get([endpoint, id].join('/'));
    }

    setDefaultHeader(key: string, value: string) {
        if (value) {
            this.headers = this.headers.set(key.toLowerCase(), value);
        } else if (this.headers.has(key.toLowerCase())) {
            this.headers = this.headers.delete(key.toLowerCase());
        }
    }

    setToken(token: string): void {
        this.setDefaultHeader('token', token);
    }
}

import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';

import * as moment from 'moment';
import { IonRouterOutlet, IonApp } from '@ionic/angular/standalone';

import { addIcons } from 'ionicons';
import * as icons from 'ionicons/icons';

@Component({
    selector: 'app-root',
    standalone: true,
    templateUrl: 'app.component.html',
    styleUrls: ['app.component.scss'],
    imports: [IonApp, IonRouterOutlet, CommonModule],
})
export class AppComponent implements OnInit {
    ngOnInit() {
        moment.locale('sv');

        addIcons({ ...icons });
    }
}

import { add, isBefore } from 'date-fns';

import * as moment from 'moment';

import { File } from './file';
import { Model, ModelDataWithoutFunctions, ModelParameter } from './model';
import { Reservation } from './reservation';
import { User } from './user';

export class Activity extends Model {
    title: ModelParameter<string>;
    description: ModelParameter<string>;
    type: ModelParameter<'trip' | 'course' | 'guiding'>;
    images: ModelParameter<
        {
            position: number;
            url: string;
        }[],
        []
    > = [];
    files: ModelParameter<
        {
            position: number;
            url: string;
            mime: string;
            title: string;
        }[],
        []
    > = [];
    infoFile: ModelParameter<{
        url: string;
        mime: string;
        title: string;
    }>;
    country: ModelParameter<{
        title: string;
        code: string;
    }>;
    primaryImage: ModelParameter<string>;
    guide: ModelParameter<string>;
    accomodation: ModelParameter<string>;
    transport: ModelParameter<string>;
    amountMin: ModelParameter<number>;
    amountMax: ModelParameter<number>;
    price: ModelParameter<number>;
    startDate: ModelParameter<string>;
    endDate: ModelParameter<string>;
    startTime: ModelParameter<string>;
    endTime: ModelParameter<string>;
    femalesOnly: ModelParameter<boolean>;
    reserveSpotsOnly: ModelParameter<boolean>;
    hidden: ModelParameter<boolean>;
    fullyBooked: ModelParameter<boolean>;
    difficulty: ModelParameter<string>;
    registrationFee: ModelParameter<number>;
    singleBedroomFee: ModelParameter<number>;
    priceInformation: ModelParameter<string>;
    plus: ModelParameter<boolean>;
    bookingUrl: ModelParameter<string>;
    partner: ModelParameter<string>;
    newFromDate: ModelParameter<string>;
    timespan: ModelParameter<string>;
    projectNumber: ModelParameter<string>;
    reservations: Reservation[];
    media: ModelParameter<File[], []> = [];
    miscMedia: ModelParameter<File[], []> = [];
    primaryMedia: ModelParameter<File>;
    infoMedia: ModelParameter<File>;
    users: ModelParameter<User[], []> = [];
    adminComment: ModelParameter<string>;
    ownerComment: ModelParameter<string>;

    isNew = false;
    isStartDaySameAsEndDay = false;
    isStartYearSameAsEndYear = false;

    // fields = [
    //     'title',
    //     'description',
    //     'type',
    //     'images',
    //     'primaryImage',
    //     'guide',
    //     'accomodation',
    //     'transport',
    //     'amountMin',
    //     'amountMax',
    //     'price',
    //     'startDate',
    //     'endDate',
    //     'startTime',
    //     'endTime',
    //     'femalesOnly',
    //     'reserveSpotsOnly',
    //     'hidden',
    //     'fullyBooked',
    //     'difficulty',
    //     'registrationFee',
    //     'singleBedroomFee',
    //     'priceInformation',
    //     'plus',
    //     'files',
    //     'infoFile',
    //     'country',
    //     'bookingUrl',
    //     'partner',
    //     'newFromDate',
    //     'projectNumber',
    //     'ownerComment',
    //     'adminComment',
    // ];

    // Omit isNew, isStartDaySameAsEndDay, isStartYearSameAsEndYear
    constructor(data?: Omit<ModelDataWithoutFunctions<Activity>, 'isNew' | 'isStartDaySameAsEndDay' | 'isStartYearSameAsEndYear'>) {
        super(data);

        if (data) {
            this.title = data.title;
            this.description = data.description;
            this.type = data.type;
            this.infoFile = data.infoFile;
            this.country = data.country;
            this.primaryImage = data.primaryImage;
            this.guide = data.guide;
            this.accomodation = data.accomodation;
            this.transport = data.transport;
            this.amountMin = data.amountMin;
            this.amountMax = data.amountMax;
            this.price = data.price;
            this.startDate = data.startDate;
            this.endDate = data.endDate;
            this.startTime = data.startTime;
            this.endTime = data.endTime;
            this.femalesOnly = data.femalesOnly;
            this.reserveSpotsOnly = data.reserveSpotsOnly;
            this.hidden = data.hidden;
            this.fullyBooked = data.fullyBooked;
            this.difficulty = data.difficulty;
            this.registrationFee = data.registrationFee;
            this.singleBedroomFee = data.singleBedroomFee;
            this.priceInformation = data.priceInformation;
            this.plus = data.plus;
            this.bookingUrl = data.bookingUrl;
            this.partner = data.partner;
            this.newFromDate = data.newFromDate;
            this.projectNumber = data.projectNumber;
            this.adminComment = data.adminComment;
            this.ownerComment = data.ownerComment;

            if (data.images) {
                this.images = data.images;
            }

            if (data.files) {
                this.files = data.files;
            }

            if (data.reservations) {
                this.reservations = data.reservations.map((reservation) => new Reservation(reservation));
            }

            if (data.users?.length) {
                this.users = data.users.map((user) => new User(user));
            }

            if (data.media?.length) {
                this.media = data.media.map((file) => new File(file));
            }

            if (data.miscMedia?.length) {
                this.miscMedia = data.miscMedia.map((file) => new File(file));
            }

            if (data.infoMedia) {
                this.infoMedia = new File(data.infoMedia);
            }

            if (data.primaryMedia) {
                this.primaryMedia = new File(data.primaryMedia);
            }
        }

        if (this.newFromDate?.length && isBefore(new Date(), add(new Date(this.newFromDate), { days: 21 }))) {
            this.isNew = true;
        }

        this.isStartDaySameAsEndDay = this._isStartDaySameAsEndDay(this.startDate, this.endDate);
        this.isStartYearSameAsEndYear = this._isStartYearSameAsEndYear(this.startDate, this.endDate);

        /**
         * Set the hour to 10 o'clock to make sure the timezone bug doesn't happen anymore.
         **/
        if (this.startDate) {
            this.startDate = moment(this.startDate).set({ hour: 10 }).format('YYYY-MM-DD HH:mm:ss');
        }

        /**
         * Set the hour to 10 o'clock to make sure the timezone bug doesn't happen anymore.
         **/
        if (this.endDate) {
            this.endDate = moment(this.endDate).set({ hour: 10 }).format('YYYY-MM-DD HH:mm:ss');
        }
        if (data) {
            if (
                this._isStartYearSameAsEndYear(this.startDate, this.endDate) &&
                !this._isStartDaySameAsEndDay(this.startDate, this.endDate)
            ) {
                const startDate = moment(data.startDate).format('D MMMM');
                const startTime = data.startTime ? moment(data.startTime).format('HH:mm') : '';
                const endDate = moment(data.endDate).format('D MMMM YYYY');
                const endTime = data.endTime ? moment(data.endTime).format('HH:mm') : '';

                this.timespan = `${startDate} ${startTime} - ${endDate} ${endTime}`;
            } else if (
                !this._isStartYearSameAsEndYear(data.startDate, data.endDate) &&
                !this._isStartDaySameAsEndDay(data.startDate, data.endDate)
            ) {
                const startDate = moment(data.startDate).format('D MMMM YYYY');
                const startTime = data.startTime ? moment(data.startTime).format('HH:mm') : '';
                const endDate = moment(data.endDate).format('D MMMM YYYY');
                const endTime = data.endTime ? moment(data.endTime).format('HH:mm') : '';

                this.timespan = `${startDate} ${startTime} - ${endDate} ${endTime}`;
            } else if (this._isStartDaySameAsEndDay(data.startDate, data.endDate)) {
                const startDateData = data.startDate || moment();
                const startDate = moment(startDateData).format('D MMMM YYYY');
                const startTime = data.startTime ? moment(data.startTime).format('HH:mm') : '';
                const endTime = data.endTime ? moment(data.endTime).format('HH:mm') : '';

                this.timespan = `${startDate} ${startTime} - ${endTime}`;
            }
        }
    }

    get reservationCount() {
        return this.reservations.length;
    }

    getTypeColor() {
        switch (this.type) {
            case 'trip':
                return 'dark';

            case 'guiding':
                return 'light';

            case 'course':
                return 'light';

            default:
                return 'light';
        }
    }

    getTypeBackground() {
        switch (this.type) {
            case 'trip':
                return '#FFEE66';

            case 'guiding':
                return '#38b938';

            case 'course':
                return '#cf6fef';

            default:
                return '#FFEE66';
        }
    }

    private _isStartYearSameAsEndYear(startDate: string | moment.Moment = moment(), endDate: string | moment.Moment = moment()): boolean {
        if (!startDate) {
            startDate = moment();
        }

        if (!endDate) {
            endDate = moment();
        }

        const startYear = moment(startDate).format('YYYY');
        const endYear = moment(endDate).format('YYYY');

        if (startYear === endYear) {
            return true;
        }

        return false;
    }

    private _isStartDaySameAsEndDay(startDate: string | moment.Moment = moment(), endDate: string | moment.Moment = moment()): boolean {
        if (!startDate) {
            startDate = moment();
        }

        if (!endDate) {
            endDate = moment();
        }

        const startDay = moment(startDate).format('YYYY-MM-DD');
        const endDay = moment(endDate).format('YYYY-MM-DD');

        if (startDay === endDay) {
            return true;
        }

        return false;
    }
}

import { Store } from '@ngxs/store';

import { of, Subject } from 'rxjs';
import { catchError, filter, switchMap, takeUntil, tap, timeout } from 'rxjs/operators';

import { AppState, AuthState, AuthStateModel, RefreshToken, SignOut } from '@shared/state';

export function appInit(store: Store): any {
    return async () => {
        const initted$ = new Subject();

        return await store
            .select(AppState.stateInitialized)
            .pipe(
                takeUntil(initted$),
                filter((stateInitialized) => stateInitialized),
                switchMap((_) => store.selectOnce(AuthState.everything)),
                switchMap((authState: AuthStateModel) => {
                    if (authState.user && authState.token && authState.refreshToken) {
                        return store.dispatch(new RefreshToken()).pipe(
                            timeout(3000),
                            tap(() => {
                                initted$.next(null);
                            }),
                            catchError((e) => {
                                store.dispatch(new SignOut());

                                return of(null);
                            }),
                        );
                    } else {
                        initted$.next(null);
                    }

                    return of(true);
                }),
            )
            .toPromise();
    };
}

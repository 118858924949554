import { enableProdMode, APP_INITIALIZER, importProvidersFrom } from '@angular/core';
import { bootstrapApplication } from '@angular/platform-browser';
import { provideHttpClient } from '@angular/common/http';
import { RouteReuseStrategy, provideRouter } from '@angular/router';

import { IonicRouteStrategy, NavController, provideIonicAngular } from '@ionic/angular/standalone';

import { Actions, Store, provideStore } from '@ngxs/store';
import { withNgxsLoggerPlugin } from '@ngxs/logger-plugin';
import { withNgxsStoragePlugin } from '@ngxs/storage-plugin';

import { DateFnsModule, DateFnsConfigurationService } from 'ngx-date-fns';
import { sv } from 'date-fns/locale';

import { AppComponent } from '@app/app.component';
import { appInit } from '@shared/helpers';
import { AppState, AuthState } from '@shared/state';
import { environment } from '@env';
import { provideAnimations } from '@angular/platform-browser/animations';

import { User } from '@shared/models';
import { routes } from '@app/routes';

const swedishConfig = new DateFnsConfigurationService();
swedishConfig.setLocale(sv);

if (environment.production) {
    enableProdMode();
}

bootstrapApplication(AppComponent, {
    providers: [
        importProvidersFrom([DateFnsModule.forRoot()]),
        provideIonicAngular({
            mode: 'ios',
            backButtonText: 'Tillbaka',
        }),
        provideRouter(routes),
        provideHttpClient(),
        provideAnimations(),
        provideStore(
            [AppState, AuthState],
            {
                developmentMode: !environment.production,
            },
            withNgxsLoggerPlugin({ disabled: environment.production }),
            withNgxsStoragePlugin({
                keys: ['auth'],
                afterDeserialize(obj, key) {
                    if (key === 'auth') {
                        if (obj.user) {
                            obj.user === new User(obj.user);
                        }
                    }

                    return obj;
                },
            }),
        ),
        { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
        { provide: DateFnsConfigurationService, useValue: swedishConfig },
        {
            provide: APP_INITIALIZER,
            useFactory: appInit,
            deps: [Store, Actions, NavController],
            multi: true,
        },
    ],
}).catch((err) => console.error(err));

import { File } from './file';
import { Model, ModelDataWithoutFunctions, ModelParameter } from './model';

export class Advertiser extends Model {
    file: ModelParameter<File>;
    level: ModelParameter<number>;
    url: ModelParameter<string>;

    constructor(data?: ModelDataWithoutFunctions<Advertiser>) {
        super(data);

        if (data) {
            this.level = data.level;
            this.url = data.url;

            if (data.file) {
                this.file = new File(data.file);
            }
        }
    }
}

import { Model, ModelDataWithoutFunctions, ModelParameter } from './model';

export class Participant extends Model {
    firstName: ModelParameter<string>;
    lastName: ModelParameter<string>;
    address: ModelParameter<string>;
    city: ModelParameter<string>;
    postalCode: ModelParameter<string>;
    phoneNumber: ModelParameter<string>;
    email: ModelParameter<string>;
    miscInfo: ModelParameter<string>;
    country: ModelParameter<string>;
    socialSecurityNumber: ModelParameter<string>;
    handBinoculars: ModelParameter<boolean>;
    scopeBinoculars: ModelParameter<boolean>;
    suggestDoubleRoomPartner: ModelParameter<boolean>;
    suggestedDoubleRoomPartner: ModelParameter<string>;
    singleBedroom: ModelParameter<boolean>;
    type: ModelParameter<string>;

    constructor(data?: ModelDataWithoutFunctions<Participant>) {
        super(data);

        if (data) {
            this.firstName = data.firstName;
            this.lastName = data.lastName;
            this.address = data.address;
            this.city = data.city;
            this.postalCode = data.postalCode;
            this.phoneNumber = data.phoneNumber;
            this.email = data.email;
            this.miscInfo = data.miscInfo;
            this.country = data.country;
            this.socialSecurityNumber = data.socialSecurityNumber;
            this.handBinoculars = data.handBinoculars;
            this.scopeBinoculars = data.scopeBinoculars;
            this.suggestDoubleRoomPartner = data.suggestDoubleRoomPartner;
            this.suggestedDoubleRoomPartner = data.suggestedDoubleRoomPartner;
            this.singleBedroom = data.singleBedroom;
            this.type = data.type;
        }

        if (this.firstName) {
            this.firstName = this.firstName.charAt(0).toUpperCase() + this.firstName.slice(1);
        }

        if (this.lastName) {
            this.lastName = this.lastName.charAt(0).toUpperCase() + this.lastName.slice(1);
        }
    }
}

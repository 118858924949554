import { Model, ModelDataWithoutFunctions, ModelParameter } from './model';

export class File extends Model {
    title: ModelParameter<string>;
    mimetype: ModelParameter<string>;
    folderUuid: ModelParameter<string>;

    constructor(data?: ModelDataWithoutFunctions<File>) {
        super(data);

        if (data) {
            this.title = data.title;
            this.mimetype = data.mimetype;
            this.folderUuid = data.folderUuid;
        }
    }
}
